<template>
  <div class="container">
      <header>
          华西集团-易方汇智对账系统
          <div class="loginOut" @click="loginOut">
              退出登录
              <i class="el-icon-switch-button"></i>
          </div>
      </header>
      <div class="top">
          <div class="top-title">
              <i class="el-icon-back" @click="$router.push('/HxIndex')"></i>
              <div>历史账单</div>
          </div>
          <div class="btn-box">
              <div class="name">结算月份</div>
              <el-date-picker
                v-model="timeArr"
                size="small"
                type="monthrange"
                :clearable="false"
                value-format="yyyy-MM-dd hh:mm:ss"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
              </el-date-picker>
              <div class="name" style="margin-left:10px">项目名称</div>
              <el-input size="small" clearable v-model="searchItem.projectName" placeholder="请输入项目名称"></el-input>
              <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索</el-button>
              <el-button type="primary" icon="el-icon-refresh" size="small" @click="resetBtn">重置</el-button>
              <download-excel
                    class="excel"
                    :data="json_data"
                    :fields="json_fields"
                    :name="fileName">
                <i :class="{'el-icon-loading':excelLoading,'el-icon-download':excelLoading==false}"></i>
                导出EXCEL
              </download-excel>
              <el-button type="success" icon="el-icon-document-checked" size="small" @click="dpaymentCollectionVisible=true" style="margin-left:15px">回款</el-button>
          </div>
      </div>
      <div class="table-box">
        <el-table
            :data="tableData"
            :max-height="tableHeight"
            style="width: 100%;height: 100%;"
            :border="true"
            :header-cell-style="{ background: '#EBEEF8',height: '45px', 'text-align':'center' }"
            :cell-style="{padding:'0',height: lineHeight}">
                <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                <el-table-column prop="imei" label="设备编号" width="150" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sceneNo" label="现场编号" width="90" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="设备类型" width="80" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.deviceType==1?'塔机':'升降机'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="projectName" label="所属项目" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="服务周期" width="180" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.leaseStartDate+"至"+scope.row.leaseEndDate}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="租赁费用(元)" width="110" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.leaseCost / 100}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="当月费用(元)" width="110" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.thisMonthLeaseCost / 100}}</span>
                    </template>
                </el-table-column>
                 <el-table-column label="0.5%费用(元)" width="110" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.thisMonthCommission / 100}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="10%押金(元)" width="110" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.thisMonthDeposit / 100}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="应付租金(元)" width="110" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.thisMonthLeaseReceivables / 100}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="支付状态" width="80" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span :style="{color:scope.row.isLeaseReceived == 1?'green':'red'}">{{scope.row.isLeaseReceived == 1?'已支付':"未支付"}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="记录月数" width="80" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.calculateDate.slice(0,7)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="安装工单" width="80" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div @click="showDialog(scope.row.id,1)" style="color:#02A7F0;cursor:pointer">查看</div>
                    </template>
                </el-table-column>
                <el-table-column label="拆除工单" width="80" align="center">
                    <template slot-scope="scope">
                        <div @click="showDialog(scope.row.id,2)" style="color:#02A7F0;cursor:pointer">查看</div>
                    </template>
                </el-table-column>
                
        </el-table>
    </div>
    <!-- 工单dialog -->
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="70%"
        style="margin-top: -90px"
        :close-on-click-modal="false"
        center>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">确定</el-button>
        </span>
    </el-dialog>
    <!-- 回款dialog -->
    <el-dialog
        :title="dpaymentCollectionType==false?'回月份应收款':'回上年度押金'"
        :visible.sync="dpaymentCollectionVisible"
        width="25%"
        style="margin-top: -90px;"
        :close-on-click-modal="false"
        center>
        <el-switch
            style="display: block;margin-bottom:20px;"
            v-model="dpaymentCollectionType"
            active-color="#13ce66"
            inactive-color="#409eff"
            active-text="回上年度押金"
            inactive-text="回月份应收款">
        </el-switch>
        <div v-if="dpaymentCollectionType==false" style="margin-top:40px;margin-bottom:5px">选择回款月份</div>
        <el-date-picker
            v-if="dpaymentCollectionType==false"
            v-model="dpaymentCollectionTime"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="month"
            :clearable="false"
            placeholder="选择月份">
        </el-date-picker>
        <span slot="footer" class="dialog-footer" >
            <el-button style="margin-top:50px" type="primary" @click="paymentCollectionBtn">确定回款</el-button>
        </span>
    </el-dialog>
    <div class="page">
        <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
        :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
    </div>
  </div>
</template>

<script>
import dateFormat from "../../assets/js/dateFormat"
export default {
    data(){
        return{
            timeArr:[],
            dpaymentCollectionTime:"",
            dpaymentCollectionType:false,
            tableHeight: '',
            lineHeight: '',
            pageList: [13,20,30,50],
            totalPage:0,
            tableData:[],
            option: {
                pageIndex: 1,
                pageSize: 13,
            },
            searchItem: {
                pageSize: 13,
                pageIndex: 1,
                startDate: "",
                endDate: "",
                projectName: "",
            },
            dialogTitle:"",
            dialogVisible:false,
            dpaymentCollectionVisible:false,
            fileName:"",
            json_fields: {},
            json_data: [],
            exportData: [],
            exportList: [
                {label: "设备编号", prop: "imei"},
                {label: "现场编号", prop: "sceneNo"},
                {label: "设备类型", prop: "deviceType"},
                {label: "所属项目", prop: "projectName"},
                {label: "服务周期", prop: "serveCycle"},
                {label: "租赁费用(元)", prop: "leaseCost"},
                {label: "当月费用(元)", prop: "thisMonthLeaseCost"},
                {label: "0.5%费用(元)", prop: "thisMonthCommission"},
                {label: "10%押金(元)", prop: "thisMonthDeposit"},
                {label: "应付租金(元)", prop: "thisMonthLeaseReceivables"},
                {label: "支付状态", prop: "isLeaseReceived"},
                {label: "记录月数", prop: "calculateDate"},
            ],
            excelLoading:false
        }
    },
    methods:{
        // 获取数据
        historyPage(val){
            this.$api.historyPage(val).then(res=>{
                if(res.code != 200) return;
                this.tableData = res.data.content;
                this.totalPage = res.data.totalElements;
            })
        },
        // 页面index改变
        pageChange(val){
            this.searchItem.pageIndex = val;
            this.historyPage(this.searchItem);
        },
        // 页面size改变
        pageSizeChange(val){
            this.searchItem.pageSize = val;
            this.historyPage(this.searchItem);
        },
        // 搜索
        searchBtn(){
            this.searchItem.pageIndex = 1;
            this.searchItem.startDate = this.timeArr[0];
            this.searchItem.endDate = this.timeArr[1];
            this.historyPage(this.searchItem);
            this.getExcelData();
        },
        // 重置
        resetBtn(){
            this.searchItem = {
                pageSize: 13,
                pageIndex: 1,
                startDate: "",
                endDate: "",
                projectName: "",
            }
            let date1 = dateFormat("YYYY-MM-dd",new Date()) + " 00:00:00";
            let date2 = dateFormat("YYYY-mm-dd",new Date()) + " 00:00:00";
            this.timeArr = [date1,date2];
            this.searchItem.startDate = date1;
            this.searchItem.endDate = date2;
            this.historyPage(this.searchItem);
            this.getExcelData();
        },
        // 加载用于导出excel的数据
        getExcelData() {
            this.excelLoading = true;
            this.fileName = "历史账单"
            this.exportData = [];
            let excel_option = {
                pageSize: 100000,
                pageIndex: 1,
                startDate: this.searchItem.startDate,
                endDate: this.searchItem.endDate,
                projectName: this.searchItem.projectName
            }
            this.$api.historyPage(excel_option).then(res => {
                this.excelLoading = false;
                if(res.code != 200) {
                    this.$message.error(res.message) 
                    return
                };
                for (var item of res.data.content) {
                    item.serveCycle = item.leaseStartDate+"至"+item.leaseEndDate
                    item.deviceType = item.deviceType==1?'塔机':'升降机'
                    item.leaseCost = item.leaseCost / 100
                    item.thisMonthLeaseCost = item.thisMonthLeaseCost / 100
                    item.thisMonthCommission = item.thisMonthCommission / 100
                    item.thisMonthDeposit = item.thisMonthDeposit / 100
                    item.thisMonthLeaseReceivables = item.thisMonthLeaseReceivables / 100
                    item.isLeaseReceived = item.isLeaseReceived == 1?'已支付':"未支付"
                    item.calculateDate = item.calculateDate.slice(0,4) + " " + item.calculateDate.slice(5,7)
                }
                this.exportData = res.data.content
                this.json_data = res.data.content
            })
        },
        // 确认回款按钮
        paymentCollectionBtn(){
            // this.dpaymentCollectionType==true:回上年度押金,  false:回月份应收款
            if(this.dpaymentCollectionType){
                this.$api.receiveDeposit().then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        this.historyPage(this.searchItem);
                        this.getExcelData();
                        this.dpaymentCollectionVisible = false;
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }else{
                this.$api.receiveMoney({receiveDate:this.dpaymentCollectionTime}).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        this.historyPage(this.searchItem);
                        this.getExcelData();
                        this.dpaymentCollectionVisible = false;
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
        },
        // 工单dialog 显示
        showDialog(id,index){
            // index==1:安装工单 index==2:拆除工单 
            this.dialogVisible = true;
            if(index == 1){
                this.dialogTitle = "安装工单"
            }else{
                this.dialogTitle = "拆除工单"
            }
        },
        loginOut(){
            this.$router.push('/HxLogin')
        }
    },
    mounted(){
        this.historyPage(this.searchItem);
        this.getExcelData();
    },
    created(){
        // 默认日期
        let date1 = dateFormat("YYYY-MM-dd",new Date()) + " 00:00:00";
        let date2 = dateFormat("YYYY-mm-dd",new Date()) + " 00:00:00";
        this.timeArr = [date1,date2];
        this.searchItem.startDate = date1;
        this.searchItem.endDate = date2;
        this.dpaymentCollectionTime = date2;
        // 表格高度、行高
        this.tableHeight = (window.innerHeight)*0.72
        if (window.screen.height > 900) {
            this.lineHeight = window.screen.height * 0.045 + 'px';
        } else {
            this.lineHeight = window.screen.height * 0.035 + 'px';
        }
        // 初始化导出文件格式
        this.exportList.map((item, i) => {
            this.json_fields[item.label] = {
                field: item.prop,
                callback: value => {
                    return "" + value;
                }
            }
        });
    }
}
</script>

<style lang="scss" scoped>
.container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(240, 242, 245, 1);
    overflow: hidden;
}
header{
    background: #fff;
    height: 65px;
    font-weight: bold;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .loginOut{
        position: absolute;
        right: 2%;
        color: #169bd5;
        font-size: 17px;
        cursor: pointer;
        &:hover{color: rgba($color: #169bd5, $alpha: 0.8);}
        i{font-weight: bold;}
    }
}
.top{
    .top-title{
        height: 60px;
        display: flex;
        align-items: center;
        padding-left: 28px;
        i{
            font-size: 30px;
            cursor: pointer;
        }
        div{
            font-weight: bold;
            margin-left: 20px;
            font-size: 19px;
        }
    }
    .btn-box{
        display: flex;
        align-items: center;
        padding-left: 30px;
        height: 60px;
        .name{
            width: 80px;
            height: 32px;
            line-height: 32px;
        }
        .el-input{
            margin: 0 15px;
            width: 300px;
        }
    }
}
.table-box{
    flex: 1;
    margin: 0 10px;
}
.page{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
.excel{
    width: 100px;
    height: 32px;
    color: #fff;
    font-size: 12px;
    background: #409eff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    &:hover{
        background: rgba($color: #409eff, $alpha: 0.8);
    }
    &:active{
        background: rgba($color: #409eff, $alpha: 1);
    }
    i{
        margin-right: 5px;
    }
}
</style>